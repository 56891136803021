<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<OrderNav></OrderNav>
			</div>
			<div class="admin-col whole_000 flex_bd">
				<div class="order_box">
					<!-- <div class="order-top whole_000">
						<div class="or-top-b">{{$t('meun11_4')}}</div>
					</div> -->
					<div class="order-top order-body whole_fff">
						<div class="order-table orf_table">
							<el-table :data="tableData" style="width: 100%" @cell-click="cellClick">
								<el-table-column prop="activityName" :label="$t('cxth1')">
									<template slot-scope="scope">
										<span style="color: #2d8cf0;">{{scope.row.activityName}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="type" :label="$t('cxth2')">
									<template slot-scope="scope">
										<div v-if="scope.row.type == 1">{{$t('labType1')}}</div>
										<div v-if="scope.row.type == 2">{{$t('labType2')}}</div>
										<div v-if="scope.row.type == 3">{{$t('labType3')}}</div>
										<div v-if="scope.row.type == 4">{{$t('labType4')}}</div>
										<div v-if="scope.row.type == 5">{{$t('labType5')}}</div>
										<div v-if="scope.row.type == 6">{{$t('labType6')}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="amount" :label="$t('cxth3')">
								</el-table-column>
								<el-table-column prop="baseSymbol" :label="$t('cxth4')">
								</el-table-column>
								<el-table-column prop="coinSymbol" :label="$t('cxth5')">
								</el-table-column>
								<el-table-column prop="state" :label="$t('cxth6')">
									<template slot-scope="scope">
										<div v-if="scope.row.type == 5">
											<div v-if="scope.row.state == 1">
												{{$t('cxstate1')}}
											</div>
											<div v-if="scope.row.state == 2">
												{{$t('cxstate2')}}
											</div>
											<div v-if="scope.row.state == 3">
												{{$t('cxstate3')}}
											</div>
										</div>
										<div v-else>
											<div v-if="scope.row.state == 1">
												{{$t('cxstate4')}}
											</div>
											<div v-if="scope.row.state == 2">
												{{$t('wth4')}}
											</div>
											<div v-if="scope.row.state == 3">
												{{$t('cxstate3')}}
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column prop="turnover" :label="$t('cxth7')">
									<template slot-scope="scope">
										<div>{{scope.row.turnover}} {{scope.row.baseSymbol}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="createTime" :label="$t('cxth8')">
								</el-table-column>
								<template slot="empty">
									<img src="../../assets/photo/exte1.png" alt="">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
						</div>
						<div class="exte-box">
							<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
								layout="prev, pager, next" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		getmyorders
	} from '@/api/api/active'
	import OrderNav from '@/components/OrderNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			OrderNav,
			Foot
		},
		data() {
			return {
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				getmyorders({
					pageNo: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.data.content
						this.total = res.data.totalElements
						this.pageSize = res.data.size
					} else {
						this.$message.error(this.$t('loginFirst'));
					}

				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			cellClick(row) {
				const routeUrl = this.$router.resolve({
					path: '/lab/detail/' + row.activityId
				})
				window.open(routeUrl.href, '_blank')
			}
		}
	}
</script>
